import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Button } from '@nextui-org/react';
import { signInWithCustomToken } from 'firebase/auth';
import { frommClient } from '~/src/fromm';

export default function Token() {
  let [customToken, setCustomToken] = useState('');
  let [isLoading, setIsLoading] = useState(false);
  let [error, setError] = useState<string | null>(null);
  let navigate = useNavigate();

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      if (customToken) {
        await signInWithCustomToken(
          frommClient.auth,
          customToken.replace(/\s+/g, ''),
        );
        let user = frommClient.auth.currentUser;
        if (user) {
          let idTokenResult = await user.getIdTokenResult();
          localStorage.setItem('fbOnly', 't');
          localStorage.setItem(
            'accessUserIds',
            JSON.stringify(
              (idTokenResult.claims.accessUserIds as string[]).filter(
                id => id !== idTokenResult.claims.sub && id !== 'bot',
              ),
            ),
          );
        }
      }

      navigate('/friends');
    } catch (error) {
      console.error('Failed to set token:', error);
      setError(
        error instanceof Error
          ? error.message
          : 'Failed to authenticate with provided tokens',
      );
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <main className='min-h-screen flex items-center justify-center'>
      <form
        onSubmit={handleSubmit}
        className='max-w-md w-full m-4 flex flex-col gap-4'
      >
        <h2 className='text-center text-3xl font-extrabold'>
          Set Firebase Custom Token
        </h2>
        <Input
          label='Firebase Custom Token'
          value={customToken}
          onChange={e => setCustomToken(e.target.value)}
          required
          classNames={{ input: 'text-base' }}
        />
        {error && <div className='text-danger-500 text-sm'>{error}</div>}
        <Button
          type='submit'
          color='primary'
          className='font-medium'
          isLoading={isLoading}
        >
          Set Token
        </Button>
      </form>
    </main>
  );
}
