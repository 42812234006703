import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Textarea, Button } from '@nextui-org/react';

export default function Import() {
  let [input, setInput] = useState('');
  let [error, setError] = useState('');
  let [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();

  async function handleImport() {
    try {
      setIsLoading(true);
      let data = JSON.parse(input);
      let db = await new Promise<IDBDatabase>((resolve, reject) => {
        let request = indexedDB.open('firebaseLocalStorageDb', 1);
        request.addEventListener('error', _e =>
          reject(new Error('Failed to open database')),
        );
        request.addEventListener('success', e =>
          resolve((e.target as IDBOpenDBRequest).result),
        );
      });
      let objectStore = db
        .transaction(['firebaseLocalStorage'], 'readwrite')
        .objectStore('firebaseLocalStorage');
      let request = objectStore.put(data);
      request.addEventListener('success', () => {
        navigate('/friends');
      });
      request.addEventListener('error', () => {
        setError('Failed to store data');
      });
      localStorage.setItem('fbOnly', 't');
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Unknown error');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <main className='min-h-screen flex items-center justify-center'>
      <div className='max-w-2xl w-full m-4 flex flex-col gap-4'>
        <h2 className='text-center text-3xl font-extrabold'>
          Import Auth Data
        </h2>
        <Textarea
          value={input}
          onValueChange={setInput}
          placeholder='Paste your Firebase auth JSON data here...'
          minRows={10}
          className='font-mono'
          classNames={{
            label: 'pb-0',
            input: 'text-base',
            helperWrapper: 'p-0',
          }}
          disabled={isLoading}
          autoFocus
        />
        {error && <div className='text-danger-500 text-sm'>{error}</div>}
        <Button
          color='primary'
          className='font-medium'
          onClick={handleImport}
          isDisabled={!input || isLoading}
        >
          {isLoading ? 'Importing...' : 'Import'}
        </Button>
      </div>
    </main>
  );
}
